<template>
  <div>
    <v-card :loading="loading">
      <v-card-text class="px-8 pt-3">
        <v-card-title class="px-0 pt-0">
          <v-text-field
            class="pt-0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="rentals"
          :search="search"
          :header-props="{ sortByText: 'Ordenar por:' }"
          :sort-desc="true"
          sort-by="id"
          :items-per-page="itemsPerPage"
          :page="page"
          @update:page="page = $event"
          @update:items-per-page="itemsPerPage = $event"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-btn
                color="primary"
                :disabled="loading"
                dark
                class="mb-4"
                style="margin-left: -17px"
                @click="$emit('rental-modal')"
                v-if="hasPermission(4096)"
              >
                Adicionar Locação
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            {{ item.id.slice(0, 8).toUpperCase() }}
          </template>
          <template v-slot:[`item.Immobiles`]="{ item }">
            <div class="d-flex">
              <v-chip v-for="i in item.Immobiles" :key="`${item.id}-${i.id}`" small>
                {{ i.Address.street }}, {{ i.Address.number }} - {{ i.Address.city }}/{{ i.Address.state }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="statusChip[item.status].color" small outlined label>
              {{ statusChip[item.status].text }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="{ name: 'rental.edit', params: { id: item.id } }" v-if="hasPermission(8192)">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" :disabled="item.status == 'finished'" icon v-if="hasPermission(16384)">
              <v-icon>mdi-file-cancel-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:no-results>
            <v-btn color="primary" @click="search = ''">Resetar</v-btn>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="page"
              :length="Math.ceil(rentals.length / itemsPerPage)"
            ></v-pagination>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <rental-modal @success="getAll" />
    <rental-delete @success="getAll" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RENTAL from '@/services/rental'
import RentalModal from '@/components/modals/RentalModal.vue'
import RentalDelete from '@/components/modals/RentalDelete.vue'

export default {
  components: { RentalDelete, RentalModal },
  data: () => ({
    loading: false,
    rentals: [],
    search: '',
    page: 1,
    itemsPerPage: 5,
    headers: [
      { text: 'ID Locação', value: 'id', sortable: true, align: 'start' },
      { text: 'Tipo', value: 'type', sortable: true, align: 'center' },
      { text: 'Endereço', value: 'Immobiles', sortable: true },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end', width: '120' },
    ],
    statusChip: {
      active: { text: 'Ativo', color: 'success' },
      pending: { text: 'Pendente', color: 'warning' },
      finished: { text: 'Finalizado', color: 'error' },
    },
  }),

  methods: {
    async getAll() {
      try {
        this.loading = true
        const response = await RENTAL.getAll()
        this.rentals = response.rentals
        this.loading = false
      } catch (e) {
        console.log(e)
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar imóveis',
        })
        this.$router.push({ name: 'dashboard' })
      }
    },
    deleteItem(item) {
      this.$emit('rental-delete', item.id)
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  mounted() {
    this.getAll()
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>
