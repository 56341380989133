<template>
  <v-dialog v-model="dialog" max-width="500px" class="text-center">
    <v-card class="pa-4">
      <v-card-title class="justify-center text-center">
        Tem certeza que deseja <br />cancelar esta locação?
      </v-card-title>
      <v-card-actions class="mt-4 justify-center">
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="destroy">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RENTAL from '@/services/rental'

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      rentalId: null,
    }
  },
  methods: {
    async destroy() {
      try {
        // TODO
        throw new Error('Não implementado');
        this.loading = true
        const response = await RENTAL.delete(this.rentalId)
        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    open(rentalId) {
      this.dialog = true
      this.rentalId = rentalId
    },
    close() {
      this.loading = false
      this.dialog = false
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'rental-delete',
    },
  },
}
</script>
<style></style>
