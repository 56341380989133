<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Adicionar Locação</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="rental.type" :mandatory="true" row hide-details class="mt-0">
                <v-radio label="Residencial" value="Residencial"></v-radio>
                <v-radio label="Comercial" value="Comercial"></v-radio>
                <v-radio label="Industrial" value="Industrial"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <ImmobileSelector
                label="Imóveis"
                v-model="rental.immobiles"
                outlined
                dense
                chips
                small-chips
                deletable-chips
                multiple
              />
            </v-col>

            <v-col cols="12">
              <ClientSelector
                outlined
                dense
                chips
                small-chips
                deletable-chips
                label="Locatários"
                v-model="rental.tenant"
                multiple
              />
            </v-col>

            <v-col cols="12">
              <ClientSelector
                outlined
                dense
                chips
                small-chips
                deletable-chips
                label="Fiadores"
                v-model="rental.guarantor"
                multiple
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RENTAL from '@/services/rental'
import ClientSelector from '../global/ClientSelector.vue'
import ImmobileSelector from '../global/ImmobileSelector.vue'
const defaultData = () => ({
  type: 'Residencial',
  immobiles: [],
  tenant: [],
  guarantor: [],
})
export default {
  components: { ClientSelector, ImmobileSelector },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      rental: defaultData(),
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        if (this.rental.immobiles.length === 0) throw new Error('Selecione ao menos um imóvel')
        if (this.rental.tenant.length === 0) throw new Error('Selecione ao menos um locatário')
        if (this.rental.guarantor.length === 0) throw new Error('Selecione ao menos um fiador')
        if (this.rental.tenant.some(t => this.rental.guarantor.includes(t)))
          throw new Error('Locatário e fiador não podem ser a mesma pessoa')

        var obj = {
          type: this.rental.type,
          immobiles: this.rental.immobiles,
          clients: [
            ...this.rental.tenant.map(t => ({ id: t, type: 'Tenant' })),
            ...this.rental.guarantor.map(g => ({ id: g, type: 'Guarantor' })),
          ],
        }

        let response = await RENTAL.create(obj)
        this.$router.push({ name: 'rental.edit', params: { id: response.rental.id } })
        this.$emit('success')
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
        this.loading = false
      }
    },
    open(data) {
      this.dialog = true
      this.immobile = Object.assign(defaultData(), data)
      if (data) {
        this.immobilePropriety = !data.ownerId
        this.immobile.owner = data.ownerId || null
        this.defaultAddress = `${data.Address.street}, ${data.Address.number} - ${data.Address.zipCode} - ${data.Address.city} - ${data.Address.state}`
      }
    },
    close() {
      this.loading = false
      this.dialog = false
      this.immobile = defaultData()
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'rental-modal',
    },
  },
}
</script>

<style></style>
